<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ statistic.type === 'duration' ? $moment.utc(statistic.value * 1000).format('mm:ss') : statistic.value }}
        </h2>
        <span class="text-capitalize">{{ statisticTitle.replace(/_/g, ' ') }}</span>
      </div>
      <div>
        <feather-icon
          size="25"
          :icon="`Arrow${getDelta().includes('+') ? 'Up' : 'Down'}RightIcon`"
          :class="`text-${getColor()}`"
        />
        <p :class="`text-${getColor()}`">{{ getDelta() }}</p>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCard,
    BCardBody,
    // BAvatar,
    FeatherIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: Object,
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
  },
  methods: {
    getColor() {
      if (this.statistic.more_is_better) {
        if (this.statistic.delta > 0) {
          return 'success'
        }
        return 'primary'
      }
      if (this.statistic.more_is_better === false) {
        if (this.statistic.delta < 0) {
          return 'success'
        }
        return 'primary'
      }
      return 'secondary'
    },
    getDelta() {
      if (this.statistic.more_is_better) {
        if (this.statistic.delta > 0) {
          return `+${this.statistic.delta}%`
        }
        return `${this.statistic.delta}%`
      }
      if (this.statistic.more_is_better === false) {
        if (this.statistic.delta < 0) {
          return `${this.statistic.delta}%`
        }
        return `+${this.statistic.delta}%`
      }
      return `${this.statistic.delta}%`
    },
  },
}
</script>
