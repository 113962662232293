<template>
  <b-row class="match-height">
    <b-col
      cols="12"
    >
      <p class="mb-2">
        <span>Rapport Statistique Gorgias.</span>
      </p>
    </b-col>
    <b-card no-body>
      <b-row>
        <b-col cols="4">
          <v-select
            v-if="users"
            v-model="user"
            placeholder="Agents"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :multiple="true"
            :options="users"
            @input="getData"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mt-2 mt-md-0"
        >
          <flat-pickr
            v-model="rangeDate"
            placeholder="Date"
            class="form-control mr-1"
            :config="{ mode: 'range'}"
            @input="getData"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(over, key) of overview.data"
          :key="key"
          lg="3"
          md="3"
          xs="6"
        >
          <statistic-card-horizontal-default
            icon="portugal.png"
            :statistic="over"
            :statistic-title="over.name"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

// import ApexLineChart from '@core/components/charts/apex-chart/ApexLineChart.vue'
// import ApexLineAreaChart from '@core/components/charts/apex-chart/ApexLineAreaChart.vue'
// import ApexDataBarChart from '@core/components/charts/apex-chart/ApexDataBarChart.vue'
// import ApexBarChart from '@core/components/charts/apex-chart/ApexBarChart.vue'
// import ApexCandlestickChart from '@core/components/charts/apex-chart/ApexCandlestickChart.vue'
// import ApexScatterChart from '@core/components/charts/apex-chart/ApexScatterChart.vue'
// import ApexHeatMapChart from '@core/components/charts/apex-chart/ApexHeatMapChart.vue'
// import ApexDonutChart from '@core/components/charts/apex-chart/ApexDonutChart.vue'
// import ApexRadialBarChart from '@core/components/charts/apex-chart/ApexRadialBarChart.vue'
// import ApexRadarChart from '@core/components/charts/apex-chart/ApexRadarChart.vue'
import StatisticCardHorizontalDefault from '@core/components/statistics-cards/StatisticCardHorizontalDefault.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    StatisticCardHorizontalDefault,
    flatPickr,
    // ApexLineChart,
    // ApexDataBarChart,
    // ApexBarChart,
    // ApexCandlestickChart,
    // ApexScatterChart,
    // ApexHeatMapChart,
    // ApexDonutChart,
    // ApexRadarChart,
    // ApexRadialBarChart,
  },
  data() {
    return {
      overview: null,
      users: null,
      user: null,
      rangeDate: null,
    }
  },
  async mounted() {
    try {
      await this.getData()
      const { users } = await this.$http.get('/admin/gorgias/users')
      console.log(users)
      this.users = users
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getData() {
      try {
        console.log('sdfqsdf', this.user)
        let filter = {}
        if (this.rangeDate && this.rangeDate.includes('to')) {
          const dates = this.rangeDate.split(' to ')
          filter = { ...filter, start_datetime: this.$moment(dates[0]).format('YYYY-MM-DDT00:00:00-05:00'), end_datetime: this.$moment(dates[1]).format('YYYY-MM-DDT23:59:59-05:00') }
        }
        if (this.user) {
          filter.user = this.user.map(u => u.id)
        }
        const { data } = await this.$http.get('/admin/gorgias', { params: { ...filter } })
        this.overview = data
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
